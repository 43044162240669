import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
};
export const createAd = (values) => async (dispatch) => {
  try {
    console.log(values);
    const response = await axiosApi.post(`admin/add/create`, values);
    console.log("Values ",values);
    if (response) {
      toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    toast.error(error.response?.data?.message);
  }
};

export const adListApi = (data) => async (dispatch) => {
  try {
    dispatch(adSetState([{ key: "isLoading", value: true }]));
    var response;
    if (data?.search != "") {
      response = await axiosApi.get(
        `admin/add/viewAll?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
        null
      );
    } else {
      response = await axiosApi.get(
        `admin/add/viewAll?page=${data?.page}&limit=${data?.limit}`,
        null
      );
    }
    if (response) {
      console.log(response?.data?.data);
      dispatch(
        adSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "adListData", value: response?.data}, // Change this
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(adSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

export const updateAdAPi =
  ({ adId, values }) =>
  async (dispatch) => {
    try {
      dispatch(adSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.put(`admin/add/update/${adId}`, values);
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          adSetState([
            { key: "isLoading", value: false },
            { key: "updateContestData", value: response?.data?.message },  // Change this
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(adSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

//** Delete Contest **\\
export const deleteAdApi =
  ({ adId, setDeleteAdModal }) =>
  async (dispatch) => {
    try {
      dispatch(
        adSetState([
          { key: "isLoading", value: true },
          { key: "deleteAdData", value: null },
        ])
      );

      const response = await axiosApi.delete(
        `admin/add/delete/${adId}`,
        null
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          adSetState([
            { key: "isError", value: null },
            { key: "isLoading", value: false },
            { key: "deleteAdData", value: response?.data?.message },  // Change this
          ])
        );
        setDeleteAdModal(false);
      }
      return response;
    } catch (error) {
      dispatch(adSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };


  const adSlice = createSlice({
    name: "ad",
    initialState: initialStates,
    reducers: {
      adSetState: (state, { payload }) => {
        if (Array.isArray(payload)) {
          for (const obj of payload) {
            _.set(state, obj.key, obj.value);
          }
        } else {
          _.set(state, payload.key, payload.value);
        }
      },
    },
  });
  
  export const { adSetState } = adSlice.actions;
  
  const { reducer } = adSlice;
  
  export default reducer;
