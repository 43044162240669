import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  tdsListData: {
    data: null,
    isLoading: false,
    isError: false,
  },
  tdsDetailsData: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

// tds Listing Api
export const tdsListingApi =
  ({ page, limit, startDate, endDate, search }) =>
  async (dispatch) => {
    try {
      dispatch(tdsState([{ key: "tdsListData.isLoading", value: true }]));
      var response;
      if (startDate!= "" && endDate!= "") {
        if (search != "") {
          response = await axiosApi.get(
            `admin/payment/tds-filter-history?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&search=${search}`
          );
        } else {
          response = await axiosApi.get(
            `admin/payment/tds-filter-history?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
          );
        }
      } else {
        if (search != "") {
          response = await axiosApi.get(
            `admin/payment/tds-filter-history?page=${page}&limit=${limit}&search=${search}`
          );
        } else {
          response = await axiosApi.get(
            `admin/payment/tds-filter-history?page=${page}&limit=${limit}`
          );
        }
      }
      if (response) {

        dispatch(
          tdsState([
            { key: "tdsListData.isError", value: false },
            { key: "tdsListData.isLoading", value: false },
            { key: "tdsListData.data", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(tdsState([{ key: "tdsListData.isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

// tds Details Api
export const tdsDetailsApi = (startDate, endDate, user) => async (dispatch) => {
  try {
    dispatch(tdsState([{ key: "tdsDetailsData.isLoading", value: true }]));

    const response = await axiosApi.get(
      `admin/payment/tds-history?startDate=${startDate}&endDate=${endDate}&user=${user}`
    );
    if (response) {
      dispatch(
        tdsState([
          { key: "tdsDetailsData.isError", value: false },
          { key: "tdsDetailsData.isLoading", value: false },
          { key: "tdsDetailsData.data", value: response?.data?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(tdsState([{ key: "tdsDetailsData.isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

const tdsSlice = createSlice({
  name: "tds",
  initialState: initialStates,
  reducers: {
    tdsState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { tdsState } = tdsSlice.actions;

const { reducer } = tdsSlice;

export default reducer;
