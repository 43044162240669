import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
  maxRankingsData: null,
  currentRankingsData: null,
};

//** Contest List **\\
export const contestListApi = (data) => async (dispatch) => {
  try {
    dispatch(contestSetState([{ key: "isLoading", value: true }]));
    var response;
    console.log("Data: ",data)
    if (data?.startDate!= "" && data?.endDate!= "") {
      if (data?.search == "") {
        response = await axiosApi.get(
          `admin/contest/list?page=${data?.page}&limit=${data?.limit}&startDate=${data?.startDate}&endDate=${data?.endDate}`,
          null
        );
      } else {
        response = await axiosApi.get(
          `admin/contest/list?page=${data?.page}&limit=${data?.limit}&search=${data?.search}&startDate=${data?.startDate}&endDate=${data?.endDate}`,
          null
        );
      }
    } else {
      if (data?.search == "") {
        response = await axiosApi.get(
          `admin/contest/list?page=${data?.page}&limit=${data?.limit}`,
          null
        );
      } else {
        response = await axiosApi.get(
          `admin/contest/list?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
          null
        );
      }
    }
    if (response) {
      dispatch(
        contestSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "contestListData", value: response?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(contestSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

//** Create Contest **\\
export const createContestAPi = (values) => async (dispatch) => {
  try {
    dispatch(contestSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.post(`admin/contest`, values);
    if (response) {
      toast.success(response?.data?.message);
      dispatch(
        contestSetState([
          { key: "isLoading", value: false },
          { key: "createContestData", value: response?.data?.message },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(contestSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

//** Update Contest **\\
export const updateContestAPi =
  ({ contestId, values }) =>
  async (dispatch) => {
    try {
      dispatch(contestSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.put(`admin/contest/${contestId}`, values);
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          contestSetState([
            { key: "isLoading", value: false },
            { key: "updateContestData", value: response?.data?.message },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(contestSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

//** Delete Contest **\\
export const deleteContestApi =
  ({ contestId, setDeleteContestModal }) =>
  async (dispatch) => {
    try {
      dispatch(
        contestSetState([
          { key: "isLoading", value: true },
          { key: "deleteContestData", value: null },
        ])
      );
      const response = await axiosApi.delete(
        `admin/contest/${contestId}`,
        null
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          contestSetState([
            { key: "isError", value: null },
            { key: "isLoading", value: false },
            { key: "deleteContestData", value: response?.data?.message },
          ])
        );
        setDeleteContestModal(false);
      }
      return response;
    } catch (error) {
      dispatch(contestSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

// update practice data
export const updatePracticeContestAPi =
  ({ contestId, data }) =>
  async (dispatch) => {
    try {
      dispatch(contestSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.put(
        `admin/contest/practice/${contestId}`,
        data
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          contestSetState([
            { key: "isLoading", value: false },
            {
              key: "updatePracticeContestData",
              value: response?.data?.data,
            },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(contestSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

// get practice data
export const getPracticeContestAPi =
  ({ contestId }) =>
  async (dispatch) => {
    try {
      dispatch(contestSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.get(
        `admin/contest/practice/${contestId}`
      );
      if (response) {
        dispatch(
          contestSetState([
            { key: "isLoading", value: false },
            {
              key: "practiceContestData",
              value: response?.data?.data,
            },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(contestSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

/* -------------------------------------------------------------------------- */
/*                            Get Recurring Recorda                           */
/* -------------------------------------------------------------------------- */
export const recurringListApi = (data) => async (dispatch) => {
  try {
    dispatch(contestSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.get(
      `admin/contest/recurring-list/${data?.ContestID}?page=${data?.page}&limit=${data?.limit}`,
      null
    );
    if (response) {
      dispatch(
        contestSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "recurringListData", value: response?.data?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(contestSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

/* -------------------------------------------------------------------------- */
/*                      Recuuring By Participants Records                     */
/* -------------------------------------------------------------------------- */
export const recurringByParticipantListApi = (data) => async (dispatch) => {
  try {
    dispatch(contestSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.get(
      `admin/contest/participate-list/${data?.recuuringID}?limit=${data?.limit}&search=&page=${data?.page}`,
      null
    );
    if (response) {
      dispatch(
        contestSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "participantsList", value: response?.data?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(contestSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

// Max-fill Api Call
export const MaxRankingsAPI = (data) => async (dispatch) => {
  try {
    dispatch(contestSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.post(`winner-board/max-sports`, data);
    if (response) {
      toast.success(response?.data?.message);
      dispatch(
        contestSetState([
          { key: "maxRankingsData", value: response?.data?.data },
          { key: "isLoading", value: false },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(contestSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

//  Current-fill Api Call
export const CurrentRankingsAPI = (data) => async (dispatch) => {
  try {
    dispatch(contestSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.post(`winner-board/current-sports`, data);
    if (response) {
      toast.success(response?.data?.message);
      dispatch(
        contestSetState([
          { key: "currentRankingsData", value: response?.data?.data },
          { key: "isLoading", value: false },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(contestSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

const contestSlice = createSlice({
  name: "contest",
  initialState: initialStates,
  reducers: {
    contestSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { contestSetState } = contestSlice.actions;

const { reducer } = contestSlice;

export default reducer;
