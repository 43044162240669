import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
};
export const locationListApi = (data) => async (dispatch) => {
  try {
    dispatch(locationSetState([{ key: "isLoading", value: true }]));
    var response;
    response = await axiosApi.get(
      `admin/location/state/viewAll?page=${data?.page}&limit=${data?.limit}`,
      null
    );
    if (response) {
      console.log(response?.data?.data);
      dispatch(
        locationSetState([
          { key: "isError", value: null },
          { key: "isLoading", value: false },
          { key: "locationListData", value: response?.data}, // Change this
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(locationSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

export const updateLocationAPi =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(locationSetState([{ key: "isLoading", value: true }]));
      console.log(payload);
      const response = await axiosApi.put(`admin/location/state/update/${id}`, payload);
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          locationSetState([
            { key: "isLoading", value: false },
            { key: "updateLocationData", value: response?.data },
          ])
        );
      }
      return Promise.resolve(response); // ✅ Success response
    } catch (error) {
      dispatch(locationSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
      return Promise.reject(error); // 🔥 Error response (Important)
    }
  };



  const locationSlice = createSlice({
    name: "notification",
    initialState: initialStates,
    reducers: {
      locationSetState: (state, { payload }) => {
        if (Array.isArray(payload)) {
          for (const obj of payload) {
            _.set(state, obj.key, obj.value);
          }
        } else {
          _.set(state, payload.key, payload.value);
        }
      },
    },
  });
  
  export const { locationSetState } = locationSlice.actions;
  
  const { reducer } = locationSlice;
  
  export default reducer;
