import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
  referralListData: null,
  userList: null,
  referralListingData: {
    data: null,
    isLoading: false,
    isError: false,
  },
};


// Payment Listing Api
export const referralListingApi = () => async (dispatch) => {
  try {
    dispatch(
      referralState([{ key: "referralListingData.isLoading", value: true }])
    );

    const response = await axiosApi.get(`admin/payment/get-refer-details`);

    if (response?.data) {
      console.log("API Success Response: ", response?.data);

      dispatch(
        referralState([
          { key: "referralListingData.isError", value: false },
          { key: "referralListingData.isLoading", value: false },
          { key: "referralListingData.data", value: response?.data },
        ])
      );
    }
    
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    
    dispatch(
      referralState([{ key: "referralListingData.isLoading", value: false }])
    );

    toast.error(error.response?.data?.message || "Something went wrong");
  }
};


const referralSlice = createSlice({
  name: "payout",
  initialState: initialStates,
  reducers: {
    referralState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { referralState } = referralSlice.actions;

const { reducer } = referralSlice;

export default reducer;
